import api from "@/api";
import Card from "@/components/Card";
import Pagination from "@/components/Pagination";
import Search from "@/components/Search";
import { CaphterParams, SmsSubjectAndChapterDto } from "@/models/subject";
import { OptionsRow, PaginationRow, SearchListRow, TableRow } from "@/models/type";
import { parseTime } from "@/utils";
import { h } from "vue";
import { Options, Vue, Watch } from "vue-property-decorator";
import ClassingDetail from "./detail";
import './index.scss'

@Options({
  components: { ClassingDetail }
})
export default class Classing extends Vue {

  public type = ''

  private searchList: Array<SearchListRow> = []

  public classList: Array<OptionsRow> = []
  public teacherList: Array<OptionsRow> = []
  public homeSubjectList: Array<OptionsRow> = []
  public statusList: Array<OptionsRow> = [
    { name: '未开启', value: 0 },
    { name: '报名中', value: 1 },
    { name: '已开始', value: 2 },
    { name: '已结束', value: 3 }
  ]

  private total = 0
  private searchObj: Detailrow = { total: 0 }

  public tableData: Array<any> = []
  public tableList: Array<TableRow> = [
    { label: '序号', slot: {
      default: (scope: any): JSX.Element => <span>{ scope.$index + 1 }</span>
    } },
    { label: '时间', slot: {
      default: (scope: any): JSX.Element => <div>
        <span>{ parseTime(new Date(scope.row.subjectStartTime).getTime(), '{y}-{m}-{d}') }</span>
        <br />
        <span>{ parseTime(new Date(scope.row.subjectEndTime).getTime(), '{y}-{m}-{d}') }</span>
      </div>
    } },
    { label: '课程类型', slot: {
      default: (scope: any): JSX.Element => <span>{ this.classList.find(item => item.value === scope.row.typeId)?.name }</span>
    } },
    { label: '名称', prop: 'title' },
    { label: '报名人数', slot: {
      default: (scope: any): JSX.Element => <span>{ `${scope.row.peopleCount}/${scope.row.peopleNumber}` }</span>
    } },
    { label: '状态', slot: {
      default: (scope: any): JSX.Element => <span class={['subject-status', `subject-status-${scope.row.status}`]}>{ this.statusList.find(item => item.value === scope.row.status)?.name }</span>
    } },
    { label: '操作', slot: {
      default: (scope: any): JSX.Element => <div>
        <span class={['span-button-primary', scope.row.loading && 'el-icon-loading']} onClick={ () => this.subjectDetail(scope.row, scope.$index) }>{ !scope.row.loading && '详情' }</span>
      </div>
    } },
  ]
  public detailRow: any = {};

  async subjectDetail(row: SmsSubjectAndChapterDto, index: number): Promise<any> {
    if(this.tableData[index].loading) {
      return
    }
    this.tableData[index].loading = true
    try {
      const params = { pageNum: 1, pageSize: 10, id: row.id as number } as CaphterParams
      const { data } = await api.subjectSubjectGetSignUpMemberBySubjectId(params)

      /* 课程名称 课程类型 报名人数 导师 */
      const obj: Detailrow = Object.assign({},
        { id: row.id },
        { title: row.title },
        { type: this.classList.find(item => item.value === row.typeId )?.name  },
        { people: `${row.peopleCount}/${row.peopleNumber}` },
        { tutor: this.teacherList.find(item => item.value === row.tutorId)?.name },
        { total: data.total || 0 },
        { list: data.list },
      )

      this.detailRow = obj
      this.type = 'detail'

      this.tableData[index].loading = false
      this.tableData = []
    } catch (error) {
      this.tableData[index].loading = false
    }
  }


  getList(): void {
    this.$nextTick(() => {
      const __pagination = this.$refs.pagination as any
      const { pageNum, pageSize } = __pagination.paginationObj as PaginationRow

      api.subjectSubjectGetSubjectList({ pageNum, pageSize, ...this.searchObj }).then(res => {
        this.tableData = res.data.list.map(item => ({ ...item, loading: false }))
        this.total = res.data.total
      })
    })
  }

  getData(): void {
    Promise.all([
      api.selectGetSysSelectTypeOption(3),
      api.subjectTutorGetTutorListSelect(),
    ]).then(res => {
      this.classList = res[0].data.map(item => ({ name: item.name, value: item.value }))
      this.teacherList = res[1].data.map(item => ({ name: item.title, value: item.id }))
      this.searchList = [
        { label: '系列课名称', type: 'input', submitKey: 'title' },
        { label: '课程类型', icon: 'svg_1', type: 'select', submitKey: 'typeId', options: this.classList },
        { label: '导师', icon: 'svg_2', type: 'select', submitKey: 'tutorId', options: this.teacherList },
        { label: '课程状态', type: 'select', options: this.statusList, submitKey: 'status' },
        { label: '时间', icon: 'svg_3', type: 'time', submitKey: ['subjectStartTime', 'subjectEndTime'] },
      ]
    })
  }

  tableDom(): JSX.Element {
    return (
      <el-table
        ref="table"
        data={ this.tableData }
      >
        {this.tableList.map(item => (
          <el-table-column
          prop={ item.prop }
          label={ item.label }
          align={ item.align || 'center' }
          v-slots={ item.slot }
        />
        ))}
      </el-table>
    )
  }

  onSearch(obj: never): void {
    this.searchObj = Object.assign({}, obj)
    this.getList()
  }

  created(): void {
    this.getData()
  }

  render(): JSX.Element {
    return this.type
      ? h(ClassingDetail, { ref: 'classingDetail' })
      : <div class="classing">
        <Card title="课程查询" class="classing-card">
          <Search list={ this.searchList } class="classing-search" onSearch={ this.onSearch } />
        </Card>
        <Card title="系列课程">
          <div class="classing-search">
            {this.tableDom()}
            <Pagination ref="pagination" total={ this.total } onCurrentChange={ this.getList } onSizeChange={ this.getList } />
          </div>
        </Card>
      </div>
  }

  @Watch('type')
  getDetail(type: string): void {
    if(type) {
      this.$nextTick(() => {
        const __classingDetail = this.$refs.classingDetail as ClassingDetail
        __classingDetail.classingForm = this.detailRow
      })
    } else {
      this.getList()
    }
  }

  mounted(): void {
    this.getList()
  }
}
export interface Detailrow {
  title?: string
  type?: string
  people?: string
  tutor?: string
  total: number
  list?: number
  id?: number
}
