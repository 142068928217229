import api from "@/api";
import Button from "@/components/Button";
import Card from "@/components/Card";
import ExportFile from "@/components/ExportFile";
import Pagination from "@/components/Pagination";
import SvgIcon from "@/components/SvgIcon";
import { SmsSubjectChapterDtos } from "@/models/subject";
import { ButtonListRow, commitT, PaginationRow, TableRow } from "@/models/type";
import { debounceClick } from "@/utils";
import { ElNotification } from "element-plus";
import { Vue } from "vue-class-component";
import Classing, { Detailrow } from ".";

export default class ClassingDetail extends Vue {

  public classingForm: Detailrow = { total: 0 }

  public formRule: any = {}
  public buttonList: ButtonListRow[] = [
    { title: '批量编辑', click: 'classingEdit', popover: true, popoverList: [
      { title: '添加签到', click: 'signIn' },
      { title: '添加返款金额', click: 'backPrice' },
    ] }
  ]

  public tableData: Array<any> = []
  public backPriceList: Array<any> = []
  public tableList: Array<TableRow> = [
    { label: '序号', width: 100, slot: {
      default: (scope: any): JSX.Element => <span>{scope.$index + 1}</span>
    } },
    { label: '真实姓名', width: 150, prop: 'memberName' },
    { label: '用户身份', width: 150, prop: 'levelName' },
    { label: '所属机构', width: 200, prop: 'institution' },
    { label: '身份证号', width: 150, prop: 'cardId' },
    { label: '手机号', width: 150, prop: 'phone' },
    { label: '邮箱', width: 150, prop: 'email' },
    { label: '签到小节数', width: 150, slot: {
      default: (scope: any): JSX.Element => <div>{scope.row.chapter ? scope.row.chapter.split(',').length : 0}</div>
    } },
    { label: '支付全额', width: 150, prop: 'payPrice' },
    { label: '返款全额', width: 150, prop: 'returnPrice' },
    { label: '操作', fixed: 'right', slot: {
      default: (scope: any): JSX.Element => <div>
        <span class="span-button-primary" onClick={ () => this.classingDetail(scope.row) }>编辑</span>
      </div>
    } },
  ]

  public exportShow = false

  public fileName = ''
  public exportLoading = false

  public signInBool = false
  public backPriceBool = false

  public ids: Array<number> = []

  public chapterList: Array<SmsSubjectChapterDtos> = []

  public chapterIds = []
  public returnPrice = ''

  public type = ''


  signIn(): void {
    if(!(this.ids.length)) {
      ElNotification({
        type: 'warning',
        message: '请选择要操作的列表'
      })
      return
    }
    this.getChapterList(this.classingForm.id as number, res => {
      this.chapterList = res.data
      this.signInBool = true
    })
  }

  signInDom(): JSX.Element {

    const slots: any = {
      footer: (): JSX.Element => (
        <span class="dialog-footer">
          <span class="dialog-footer-btn dialog-footer-btn-l" onClick={ this.signInClose } >取 消</span>
          <span class="dialog-footer-btn dialog-footer-btn-r" onClick={ () => debounceClick(this.signInSubmit) }>确 定</span>
        </span>
      )
    }

    return <el-dialog
      v-model={this.signInBool}
      title="添加签到小节"
      before-close={ this.signInClose }
      width="30%"
      v-slots={ slots }
    >
      <el-checkbox-group v-model={this.chapterIds}>
        {this.chapterList.map(item => <el-checkbox
          label={item.id}
        >
          {item.title}
        </el-checkbox>)}
      </el-checkbox-group>
      {this.type === 'edit' &&
        <div style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '20px'
        }}>
          <span style={{
            width: '120px',
            textAlign: 'right'
          }}>返款金额：</span>
          <el-input v-model={this.returnPrice} placeholder="请输入返款金额" clearable  style="width: 70%"/>
        </div>
      }
    </el-dialog>
  }

  getChapterList(id: number, cb: commitT): void {
    api.subjectSubjectGetChapterBySubjectId(id).then(res => {
      cb(res)
    })
  }

  signInSubmit(): void {
    const chapterIds = this.chapterIds.join(',')
    api.subjectSubjectSignUpAddSubjectSignUpChapter({chapterIds, returnPrice: this.returnPrice}, this.ids).then(res => {
      ElNotification({
        type: 'success',
        title: '成功',
        message: '操作成功'
      })
      this.signInClose()
      this.getList()
    })
  }

  backPriceSubmit(): void {
    api.subjectSubjectSignUpAddSubjectReturnPrice({returnPrice: this.returnPrice}, this.ids).then(res => {
      ElNotification({
        type: 'success',
        title: '成功',
        message: '添加成功'
      })
      this.backPriceClose()
      this.getList()
    })
  }

  signInClose(): void {
    this.chapterIds = []
    this.returnPrice = ''
    this.type = ''
    this.signInBool = false
  }
  backPriceClose(): void {
    this.returnPrice = ''
    this.backPriceBool = false
  }

  backPrice(): void {
    if(!(this.ids.length)) {
      ElNotification({
        type: 'warning',
        message: '请选择要操作的列表'
      })
      return
    }
    if(!this.backPriceList.every(item => {
      if( undefined == item.chapter){
        return false;
      }
      return item.chapter.split(',').length === this.backPriceList[0].chapter.split(',').length
    })) {
      ElNotification({
        type: 'warning',
        message: '已选择的成员列表签到数目必须相同'
      })
      return
    }
    this.backPriceBool = true
  }

  backPriceDom(): JSX.Element {

    const slots: any = {
      footer: (): JSX.Element => (
        <span class="dialog-footer">
          <span class="dialog-footer-btn dialog-footer-btn-l" onClick={ this.backPriceClose } >取 消</span>
          <span class="dialog-footer-btn dialog-footer-btn-r" onClick={ () => debounceClick(this.backPriceSubmit) }>确 定</span>
        </span>
      )
    }

    return <el-dialog
      v-model={this.backPriceBool}
      title="添加返款金额"
      before-close={ this.backPriceClose }
      width="30%"
      v-slots={ slots }
    >
      <div style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px'
      }}>
        <span style={{
          width: '120px',
          textAlign: 'right'
        }}>返款金额：</span>
        <el-input v-model={this.returnPrice} placeholder="请输入返款金额" clearable  style="width: 70%"/>
      </div>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px'
      }}>
        <span style={{
          width: '120px',
          textAlign: 'right'
        }}>签到小节数：</span>
        <div>{this.backPriceList[0].chapter.split(',').length}</div>
      </div>
    </el-dialog>
  }

  classingEdit(): boolean {
    return false
  }

  goBack(): void {
    const __parent = this.$parent as Classing
    __parent.type = ''
    this.classingForm = { total: 0 }
  }

  classingDetail(row: any): void {
    this.getChapterList(this.classingForm.id as number, res => {
      this.chapterIds = row?.chapter.split(',').map(item => +item) || []
      this.chapterList = res.data
      this.ids = [row.id]
      this.returnPrice = row.returnPrice
      this.type = 'edit'
      this.signInBool = true
    })
  }

  detailDom(): JSX.Element {
    return <div class="classing-detail">
      <el-row gutter={ 50 }>
        <el-col lg={ 8 } md={ 12 } sm={ 24 } class="classing-detail-cell">
          <el-form-item label="课程名称" prop="title">
            <span>{ this.classingForm.title }</span>
          </el-form-item>
        </el-col>
        <el-col lg={ 8 } md={ 12 } sm={ 24 } class="classing-detail-cell">
          <el-form-item
            v-slots={{ label: () => <>
              <SvgIcon iconClass="svg_1" />
              <span>课程类型</span>
            </>}}
            prop="typeId"
          >
            <span>{ this.classingForm.type }</span>
          </el-form-item>
        </el-col>
        <el-col lg={ 8 } md={ 12 } sm={ 24 } class="classing-detail-cell">
          <el-form-item
            v-slots={{ label: () => <>
              <SvgIcon iconClass="svg_5" />
              <span>报名人数</span>
            </>}}
            prop="peopleNumber"
          >
            <span>{ this.classingForm.people }</span>
          </el-form-item>
        </el-col>
        <el-col lg={ 8 } md={ 12 } sm={ 24 } class="classing-detail-cell">
          <el-form-item
            v-slots={{ label: () => <>
              <SvgIcon iconClass="svg_2" />
              <span>导师</span>
            </>}}
            prop="tutorId"
          >
            <span>{ this.classingForm.tutor }</span>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="classing-detail-submit" onClick={() => debounceClick(this.export)}>导出</div>
    </div>
  }

  onSelectionChange(row): void {
    this.backPriceList = row
    this.ids = row.map(item => item.id)
  }

  tableDom(): JSX.Element {
    return (
      <el-table
        ref="table"
        data={ this.classingForm.list }
        onSelectionChange={this.onSelectionChange}
      >
        <el-table-column type="selection" align="center" />
        {this.tableList.map(item => (
          <el-table-column
          prop={ item.prop }
          label={ item.label }
          width={ item.width }
          fixed={ item.fixed }
          align={ item.align || 'center' }
          v-slots={ item.slot }
        />
        ))}
      </el-table>
    )
  }

  export(): void {
    this.fileName = `${this.classingForm.title}-${this.classingForm.type} ${this.classingForm.tutor}`
    this.exportShow = true
  }

  exportClose(): void {
    this.exportShow = false
  }

  async exportSubmit(data): Promise<any> {
    this.exportLoading = true
    await api.subjectSubjectSignUpExportSubjectSignUp(this.classingForm.id as number, data)
    this.exportLoading = false
  }

  getList(): void {
    const __pagination = this.$refs.pagination as any
    const { pageNum, pageSize } = __pagination.paginationObj as PaginationRow

    api.subjectSubjectGetSignUpMemberBySubjectId({
      pageNum,
      pageSize,
      id: this.classingForm.id as number
    }).then(res => {
      this.classingForm.list = res.data.list
      this.classingForm.total = res.data.total
    })
  }

  buttonClick(click: string): void {
    this[click]()
  }

  render(): JSX.Element {
    return <el-form
      ref="form"
      model={ this.classingForm }
      class="classing"
      rules={ this.formRule }
    >
      {this.signInBool && this.signInDom()}
      {this.backPriceBool && this.backPriceDom()}
      <Card title="课程信息" back onGoBack={ this.goBack } class="classing-card">
        {this.detailDom()}
      </Card>
      <Card title="报名信息">
        <div class="classing-search">
          <Button list={ this.buttonList } onClick={this.buttonClick} onPopoverClick={ this.buttonClick }/>
          {this.tableDom()}
          <Pagination ref="pagination" total={ this.classingForm.total } onCurrentChange={ this.getList } onSizeChange={ this.getList } />
        </div>
      </Card>
      { this.exportShow && <ExportFile ref="export_file" loading={ this.exportLoading } isShow={ this.exportShow } onExportClose={ this.exportClose } onExportSubmit={ this.exportSubmit } fileName={ this.fileName } /> }
    </el-form>
  }
}
